import React from 'react'
import './App.css'
import {Button, Container} from "@mui/material"
import {Link} from 'react-router-dom'


function App() {

    return (
        <Container>

            <h1>Welcome to Math Challenges </h1>

            <img src={"/img/mario.png"} style={{padding:20}} alt={"mario"}></img>

            <h2>Start a Meth Quiz</h2>

            <ul>
                <li  style={{padding: 15}}>Version 2:
                    <Button variant="contained" color="info" sx={{ml : 2}} component={Link} to={"/q2?min=5&max=10&numQuestions=6&operand=1"}  > V2  </Button>
                </li>

                <li style={{padding: 15}}> &nbsp;6 Questions:
                    <Button variant="contained" color="success" sx={{ml : 2}} component={Link} to={"/q?max=10&numQuestions=6"}  >  Easy </Button>
                    <Button variant="contained" color="warning" sx={{ml : 2}} component={Link} to={"/q?max=20&numQuestions=6"}  >  Medium  </Button>
                    <Button variant="contained" color="error" sx={{ml : 2}} component={Link} to={"/q?max=100&numQuestions=6"}  >  Hard  </Button>
                    <Button variant="contained" color="info" sx={{ml : 2}} component={Link} to={"/q?max=1000&numQuestions=6"}  >  Super Hard  </Button>
                </li>
                <li  style={{padding: 15}}>10 Questions:
                    <Button variant="contained" color="success"sx={{ml : 2}} component={Link} to={"/q?max=10&numQuestions=10"}  >  Easy </Button>
                    <Button variant="contained" color="warning" sx={{ml : 2}} component={Link} to={"/q?max=20&numQuestions=10"}  >  Medium  </Button>
                    <Button variant="contained" color="error" sx={{ml : 2}} component={Link} to={"/q?max=100&numQuestions=10"}  >  Hard  </Button>
                </li>
                <li  style={{padding: 15}}>15 Questions:
                    <Button variant="contained" color="success" sx={{ml : 2}} component={Link} to={"/q?max=10&numQuestions=15"}  >  Easy </Button>
                    <Button variant="contained" color="warning" sx={{ml : 2}} component={Link} to={"/q?max=20&numQuestions=15"}  >  Medium  </Button>
                    <Button variant="contained" color="error" sx={{ml : 2}} component={Link} to={"/q?max=100&numQuestions=15"}  >  Hard  </Button>
                </li>
                <li  style={{padding: 15}}>20 Questions:
                    <Button variant="contained" color="success" sx={{ml : 2}} component={Link} to={"/q?max=10&numQuestions=20"}  >  Easy </Button>
                    <Button variant="contained" color="warning" sx={{ml : 2}} component={Link} to={"/q?max=20&numQuestions=20"}  >  Medium  </Button>
                    <Button variant="contained" color="error" sx={{ml : 2}} component={Link} to={"/q?max=100&numQuestions=20"}  >  Hard  </Button>
                </li>
                <li  style={{padding: 15}}>Super Questions:
                    <Button variant="contained" color="info" sx={{ml : 2}} component={Link} to={"/q?max=100000&numQuestions=50"}  > Super x 10000 Hard  </Button>
                </li>


            </ul>

        </Container>
    );
}

export default App;
